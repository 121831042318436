<template>
    <v-container>
        <v-snackbar app top right dark :timeout="4000" :value="snackbar" :color="color" class="text-center">
          {{ text }}
        </v-snackbar>
        <v-slide-y-transition>
          <div v-show="confirmedBooking">
            <v-card class="text-center">
              <v-card-title class="d-flex justify-center indigo--text"><v-icon color="success" class="mr-5">mdi-check-circle</v-icon>PNR was booked successfully.</v-card-title>
              <v-card-text>
                Thanks for booking this PNR. You will recieve an email shortly with all details.
                <br>
                <v-btn link to="/group-pnr" text color="blue" large>back to PNRs list</v-btn>
              </v-card-text>
            </v-card>
          </div>
        </v-slide-y-transition>

        <v-fade-transition>
          <div v-show="!confirmedBooking">
            <div v-if="loading && !pnr" class="text-center">
                <v-progress-circular
                :size="70"
                :width="7"
                color="indigo"
                indeterminate
                class="mx-auto my-5"
                ></v-progress-circular>
                <h3>Please wait, loading your PNR ...</h3>
            </div>
            <div v-else-if="!loading && !pnr" class="text-center">
                <h3><v-icon color="warning" left>mdi-alert</v-icon> Sorry, This PNR is not available!</h3>
            </div>
            <div v-else class="my-5">
                <div class="d-flex justify-space-between align-center">
                    <h2>{{ pnr.pnr }}</h2>
                    <v-btn dark color="indigo" tile elevation="0" :loading="loadingBooking" class="px-5 py-2" @click="bookPNR"><v-icon left>mdi-receipt</v-icon> Book PNR</v-btn>
                </div>

                <div class="my-5" v-html="pnr.pnr_details"></div>

                <v-divider></v-divider>

                <h2 class="my-5">Fare Quote:</h2>
                <ul class="body-1">
                    <li><strong>Base fare : ${{ pnr.base_fare }}</strong></li>
                    <li><strong>Estimated taxes : ${{ pnr.estimated_taxes }}</strong></li>
                    <li><strong>Deposit : ${{ pnr.deposit }}</strong></li>
                </ul>

                <v-divider class="mt-5"></v-divider>

                <h2 class="my-5">Time Limits:</h2>
                <ul class="body-1">
                    <li><strong>Name list due date: {{ pnr.name_list_due_date }}</strong></li>
                    <li><strong>Final name list: {{ pnr.final_name_list }}</strong></li>
                    <li><strong>Ticketing due date: {{ pnr.ticketing_due_date }}</strong></li>
                </ul>

                <v-divider class="my-5"></v-divider>

                <h2 class="my-5">Terms & Conditions</h2>
                <div v-html="pnr.terms"></div>

                <v-divider class="my-5"></v-divider>

                <h2 class="my-5">Penalties</h2>
                <div v-html="pnr.penalties"></div>

                <v-divider class="my-5"></v-divider>

                <h2 class="my-5">Deviations</h2>
                <div v-html="pnr.deviations"></div>

                <v-btn dark color="indigo" tile elevation="0" class="px-15 pt-5 pb-10 mt-10 d-block mx-auto" :loading="loadingBooking" @click="bookPNR"><v-icon left>mdi-receipt</v-icon> Book PNR</v-btn>
            </div>
          </div>
        </v-fade-transition>
    </v-container>
</template>

<script>
import { getPnr, bookPnr, headers } from '../../links.js'
export default {
  data () {
    return {
      pnr: null,
      loading: true,
      loadingBooking: false,
      confirmedBooking: false,
      snackbar: false,
      color: '',
      text: ''
    }
  },
  methods: {
    bookPNR () {
      this.loadingBooking = true
      this.$http.post(bookPnr(this.$route.params.id), {}, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
        if (response.body.status === 200) {
          this.confirmedBooking = true
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = response.body.message
        }
      }).catch(() => {
        this.snackbar = true
        this.color = 'error'
        this.text = 'Sorry, Something went wrong!'
      }).finally(() => {
        this.loadingBooking = false
      })
    }
  },
  created () {
    this.$http.get(getPnr(this.$route.params.id), { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      if (response.body.status) {
        this.pnr = response.body.data
      }
    }).catch(() => {}).finally(() => {
      this.loading = false
    })
  }
}
</script>
